// Define types for dateExpressions
export declare const dateExpressions: Record<string, string>;

// Define types for dateExpressionValues
export type DateExpressionValue = string;
export declare const dateExpressionValues: Record<string, DateExpressionValue>;

// Function type for getDateExpression
export declare function getDateExpression(expressionKey: string): string | undefined;

// Function type for getDateExpressionValue
export declare function getDateExpressionValue(expressionKey: string): DateExpressionValue | undefined;

// Function type for isDateExpression
export declare function isDateExpression(expression: any): expression is DateExpressionValue;

// Define types for combinedExpressions
export interface CombinedExpressionItem {
    title: string;
    operator: string;
    value: string | string[];
}
export declare const combinedExpressions: Record<string, CombinedExpressionItem>;

// Function type for tryGeCombinedtDateExpression
export declare function tryGeCombinedtDateExpression(pExpression: any): CombinedExpressionItem | null;

// Function type for getCombinedExpressionByID
export declare function getCombinedExpressionByID(pId: string): CombinedExpressionItem | null;

// Function type for getCombinedExpressionIndex
export declare function getCombinedExpressionIndex(pId: string): number | null;

// Function type for getCombinedExpressionIdByIndex
export declare function getCombinedExpressionIdByIndex(pIndex: number): string | null;